<template>
  <!-- eslint-disable  -->
  <div>
    <router-view v-if="$route.name === '子帳號清單'" />

    <div v-if="$route.name === '子通道清單'">
      <v-card flat class="w100p"></v-card>
      <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
        子通道清單
        <v-spacer></v-spacer>
      </div>

      <FilterBox
        :open.sync="isFilterOpen"
        v-model="filter"
        :loading="isLoading"
        :options="filterOptions"
        @search="fetchData"
      />

      <SizeBox height="12"></SizeBox>
      <v-card
        outlined
        :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
      >
        <v-card-text class="d-flex align-center">
          <v-spacer></v-spacer>
          <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

          <SizeBox width="14" />
          <ExportBtn
            :items="result.list"
            :headers="headers"
            :file-name="`${
              $store.getters['app/getRouteTitle']
            } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
            sheet-name="sheetName"
            :exclude="['sub_account']"
          />
        </v-card-text>
        <DataTable
          :loading="isLoading"
          :list="result.list"
          :total="result.total"
          :fields="headers"
          :replace="['sub_account', 'abnormal']"
          @get-list="fetchData"
        >
          <template #item.sub_account="{ item }">
            <div class="flex-center">
              <v-btn
                rounded
                @click="handleAction((vm) => item.sub_account.action(vm, item))"
                color="Secondary100"
                class="mx-2 my-3"
                depressed
                small
                :to="
                  item.sub_account.to ? item.sub_account.to($route, item) : null
                "
              >
                <v-icon v-if="!!item.sub_account.icon" size="18" class="mr-1">
                  {{ item.sub_account.icon }}
                </v-icon>
                {{ item.sub_account.label }}
              </v-btn>
            </div>
          </template>

          <template #item.abnormal="{ item }">
            <v-chip :color="parseTypeColor(item.abnormal)" small label>
              {{ item.abnormal === true ? '是' : '否' }}
            </v-chip>
          </template>
        </DataTable>
      </v-card>
    </div>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getChildChannelAccountingReports } from '@/api/statistics';

const sub_account = {
  label: '子帳號清單',
  icon: 'mdi-transit-detour',
  action: (vm, v) => {
    // vm.openForm('Detail', v);
  },
  to: ($route, v) => ({
    name: '子帳號清單',
    params: { ...$route.params, channel_id: v.channel_id }
  })
};

export default {
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '子通道名稱',
          value: 'channel_name',
          align: 'center',
          width: '120px'
        },
        {
          text: '子帳號清單',
          value: 'sub_account',
          align: 'center',
          width: '104px'
        },
        {
          text: '異常',
          value: 'abnormal',
          align: 'center',
          width: '30px'
        },
        {
          text: '提單總額',
          value: 'total_collect_order_amount',
          align: 'center',
          width: '104px'
        },
        {
          text: '代收手續費',
          value: 'total_collect_order_fee',
          align: 'center',
          width: '104px'
        },
        {
          text: '總用戶入帳',
          value: 'total_paid_collect_order_credit_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '代付總額',
          value: 'total_pay_order_amount',
          align: 'center',
          width: '104px'
        },
        {
          text: '代付手續費',
          value: 'total_pay_order_fee',
          align: 'center',
          width: '104px'
        },
        {
          text: '到帳金額',
          value: 'total_pay_order_actual_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '交易筆數',
          value: 'total_order',
          align: 'center',
          width: '104px'
        },
        {
          text: '交易成功率',
          value: 'success_rate',
          align: 'center',
          width: '104px'
        },
        {
          text: '成功筆數',
          value: 'success_count',
          align: 'center',
          width: '104px'
        },
        {
          text: '失敗筆數',
          value: 'failure_count',
          align: 'center',
          width: '104px'
        }
      ],
      filterOptions: [
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'created_at',
          range: true,
          withTime: true
        },
        {
          label: '成功時間',
          type: 'timeSet',
          name: 'succeeded_at',
          range: true,
          withTime: true
        },
        {
          label: '子通道名稱',
          type: 'text',
          name: 'mainQuery'
        }
      ]
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;

      const Data = await getChildChannelAccountingReports({
        channel_type_id: this.$route.params.channel_type_id,
        filter: this.filter,
        ...condition
      });
      if (!Data.error) {
        this.result.total = Data.total;
        this.result.page = Data.page;
        this.$set(
          this.result,
          'list',
          Data.items.map((item) => ({
            ...item,
            success_rate:
              Number(this.$num.multiply(item.success_rate, 100)).toFixed(2) +
              '%',
            sub_account
          }))
        );
      }

      this.isLoading = false;
    },
    handleAction(fn) {
      fn(this);
    },
    parseTypeColor(abnormalType) {
      switch (abnormalType) {
        case true:
          return 'Error300';
        case false:
          return 'Primary300';

        default:
          return 'Secondary100';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
